<template>
  <h1 class="text-2xl text-center font-semibold text-gray-900">
    Login
  </h1>
  <form
    class="space-y-3 lg:space-y-4 w-72"
    action="#"
    method="POST"
    @submit.prevent.stop="submitLogin"
  >
    <div>
      <UIInput
        id="email"
        v-model="form.email"
        name="email"
        type="email"
        placeholder="E-mailadres"
        label="E-mailadres"
        autocomplete="email"
        required=""
      />
    </div>

    <div class="mt-1">
      <UIInput
        id="password"
        v-model="form.password"
        :hidden-eye="isDefaultPassword"
        :disabled="isDefaultPassword"
        name="password"
        type="password"
        autocomplete="current-password"
        placeholder="Wachtwoord"
        label="Wachtwoord"
        required=""
      />
    </div>

    <div class="flex items-center justify-between py-2">
      <div class="flex items-center">
        <input
          id="remember-me"
          name="remember-me"
          type="checkbox"
          class="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
        >
        <label for="remember-me" class="ml-2 block text-sm text-gray-900"> Remember me </label>
      </div>

      <div class="text-sm">
        <button class="font-medium text-indigo-600 hover:text-indigo-500">
          Forgot your password?
        </button>
      </div>
    </div>

    <div>
      <button
        type="submit"
        class="w-full flex justify-center py-1 px-3 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        @click.prevent.stop="submitLogin"
      >
        Inloggen
      </button>
    </div>
  </form>
</template>

<script setup>
import { computed, reactive } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'

import UIInput from '@/components/UI/Input/Input.vue'

import isDashboard from '@/functions/isDashboard'

const route = useRoute()
const router = useRouter()
const store = useStore()
const routeQuery = computed(() => route.query || {})

const DEFAULT_EMAIL = isDashboard() ? process.env.VUE_APP_AUTH_USERNAME_DEVELOPMENT_DASHBOARD : process.env.VUE_APP_AUTH_USERNAME_DEVELOPMENT_KLANT
const DEFAULT_PASSWORD = isDashboard() ? process.env.VUE_APP_AUTH_PASSWORD_DEVELOPMENT_DASHBOARD : process.env.VUE_APP_AUTH_PASSWORD_DEVELOPMENT_KLANT

const form = reactive({
  email: DEFAULT_EMAIL || '',
  password: DEFAULT_PASSWORD || '',
})

const isDefaultPassword = computed(() => DEFAULT_PASSWORD && DEFAULT_PASSWORD === form.password)

const submitLogin = () => {
  store.dispatch('API_LOGIN', form).then(() => {
    router.push(routeQuery.value.from || '/')
  })
}
</script>
